/* Basic editor styles */
.tiptap:first-child {
    margin-top: 0;
}

/* List styles */
.tiptap ul, 
.tiptap ol {
    
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;
    list-style-position: inside; /* Ensures bullets are inside the list */
}

/* Bullet List - Customize bullet point styles */
.tiptap ul {
    list-style-type: disc; /* Disc as default bullet */
}

.tiptap ul li::marker {
    color: var(--purple-light); /* Customize bullet color */
    font-size: 1.2rem; /* Larger bullet size */
    font-weight: bold; /* Bolder bullet */
    
}

/* Ordered List - Customize number styles */
.tiptap ol {
    list-style-type: decimal; /* Numbered list */
}

.tiptap ol li::marker {
    color: var(--blue-dark); /* Customize number color */
    font-size: 1.1rem; /* Adjust size */
    font-weight: bold;
}

.tiptap ul li p, 
.tiptap ol li p {
    margin-top: 4px;
    /* margin-bottom: 0.25em; */
}

/* Add spacing between list items */
.tiptap ul li,
.tiptap ol li {
    /* margin-bottom: 0.75rem; */
    display: flex;
    margin-left: 20px;
    align-items: flex-start;
    justify-content: flex-start;
} 
 

ol li p:before, ul li p:before {
    content: ".";
    font-size: 40px;
    font-weight: 900;
    margin-right: 10px;
}

/* Heading styles */
.tiptap h1, 
.tiptap h2, 
.tiptap h3, 
.tiptap h4, 
.tiptap h5, 
.tiptap h6 {
    line-height: 1.1;
    margin-top: 2.5rem;
    word-wrap: break-word; /* 'text-wrap' is not valid in CSS, using 'word-wrap' or 'overflow-wrap' instead */
}

.tiptap h1, 
.tiptap h2 {
    margin-top: 3.5rem;
    margin-bottom: 1.5rem;
}

.tiptap h1 { 
    font-size: 1.4rem; 
}

.tiptap h2 { 
    font-size: 1.2rem; 
}

.tiptap h3 { 
    font-size: 1.1rem; 
}

.tiptap h4, 
.tiptap h5, 
.tiptap h6 { 
    font-size: 1rem; 
}

/* Code and preformatted text styles */
.tiptap code {
    background-color: var(--purple-light);
    border-radius: 0.4rem;
    color: var(--black);
    font-size: 0.85rem;
    padding: 0.25em 0.3em;
}

.tiptap pre {
    background: var(--black);
    border-radius: 0.5rem;
    color: var(--white);
    font-family: 'JetBrainsMono', monospace;
    margin: 1.5rem 0;
    padding: 0.75rem 1rem;
}

.tiptap pre code {
    background: none;
    color: inherit;
    font-size: 0.8rem;
    padding: 0;
}

/* Blockquote styles */
.tiptap blockquote {
    border-left: 3px solid var(--gray-3);
    margin: 1.5rem 0;
    padding-left: 1rem;
}

/* Horizontal rule styles */
.tiptap hr {
    border: none;
    border-top: 1px solid var(--gray-2);
    margin: 2rem 0;
}


.ProseMirror {
    word-wrap: break-word;
    white-space: pre-wrap;
    white-space: break-spaces;
    -webkit-font-variant-ligatures: none;
    font-variant-ligatures: none;
    font-feature-settings: "liga" 0;
    height: 400px !important;
    overflow-y: auto;
    outline: none !important;
}
.ProseMirror h2 {
    color: #11181C;
    font-weight: 600;
    font-size: 24px;
}

.ProseMirror h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
}

.ProseMirror ul {
    margin: 15px 0px;
}

.ProseMirror ul li {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
}

.ProseMirror ul li p {
    line-height: normal;
}